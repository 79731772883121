import React from "react";
import "./about.css";
import image from "../../assets/me-about.gif";
import { FaAward } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Conoce más</h5>
      <h2>Sobre mi</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={image} alt={image} />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experiencia</h5>
              <small>+1 año</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Proyectos</h5>
              <small>+10 completados</small>
            </article>
          </div>
          <h4 className="pad" >
            Joven de 20 años en busca de trabajo, interesado
            en desarrollarme en el área del IT dispuesto a asumir
            nuevos retos. Me adapto fácilmente
            a cualquier equipo de trabajo y
            herramientas tecnológicas.
          </h4>
          <a href="#contact" className="btn btn-primary">Conversemos</a>
        </div>
      </div>
    </section>
  );
};

export default About;
