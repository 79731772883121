import React from "react";
import './PNF.css'

export const PageNotFound = () => {
    return (
    <div className="all">
        <h1  className=".ti">
            Page not found...
        </h1>
    </div>
    );
};
