import React from "react";
import Header from "./header/Header";
import Nav from "./nav/Nav";
import About from "./about/About";
import Experience from "./experience/Experience";
import Services from "./services/Services";
import Contact from "./contact/Contact";
import Portfolio from "./portfolio/Portfolio"
import Footer from "./footer/Footer";
import Git from "./github/Git"
import './home.css'

const Portafolio = () => {
  return (
    <div>
      <Header />
      <Nav />
      <About />
      <Git />
      <Experience />
      <Portfolio />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
};

export default Portafolio;
