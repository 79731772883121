import "./git.css";

const api = "https://ghchart.rshah.org/4d0404/BrandonFabila";

const Git = () => {
  return (
    <div className="content">
      <h2 className="title">Contribuciones en GitHub</h2>
      <div className="contimg">
        <img
          className="img"
          src="https://camo.githubusercontent.com/44b9067ee5e47eacc66fad207ef07677b63646f81232aab65743a28b50395ec1/68747470733a2f2f6769746875622d726561646d652d73747265616b2d73746174732e6865726f6b756170702e636f6d3f757365723d4272616e646f6e466162696c61267468656d653d6769746875622d6461726b26686964655f626f726465723d7472756526626f726465725f7261646975733d3335266c6f63616c653d6573266d6f64653d7765656b6c7926636172645f77696474683d37353026"
          alt=""
        />
      </div>
      <div className="contimg">
        <a href="https://github.com/BrandonFabila" className="img">
          <img src={api} alt="BrandonFabila's Github chart" className="img" />
        </a>
      </div>
    </div>
  );
};

export default Git;
