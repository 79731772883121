import React from "react";
import "./portfolio.css";
import BarrasLaguna from "./BarrasLaguna.png";
import HenryMarket from "./HenryMarket.png";
import plantilla from "./plantilla.png";
import WhatsappShopping from "./ws1.png";

const Portfolio = () => {
  const data = [
    {
      id: 1,
      image: BarrasLaguna,
      title: "Barras Laguna",
      demo: "https://barraslaguna.netlify.app",
      techs:
        "Blog deportivo desarrollado con NextJS. Incluye venta de terceros con Amazon",
    },
    {
      id: 2,
      image: HenryMarket,
      title: "Henry Market",
      github: "https://github.com/BrandonFabila/Henry-Market-Front",
      demo: "https://henrymarket.netlify.app",
      techs:
        "Ecommerce desarrollado con React. Incluye pasarela de pago, carrito, registro con google y correo y panel amplio de administrador",
    },
    {
      id: 3,
      image: plantilla,
      title: "Landing page comercial",
      github: "https://github.com/BrandonFabila/plantillajuridica",
      demo: "https://nombredetunegocio.netlify.app",
      techs:
        "Plantilla básica para landing page enfocada a negocios. Desarrollada con NextJS y Tailwind",
    },
    {
      id: 4,
      image: WhatsappShopping,
      title: "Whatsappshopping",
      demo: "https://nombredeturestaurante.netlify.app/Informacion",
      techs: "Plataforma ágil para hacer pedidos, envía directo a WhatsApp. Simplifica tu experiencia de compra. React, Responsive, GoogleDrive, Excel, JS, API",
    },
  ];

  return (
    <section id="portfolio">
      <h5>Mi trabajo reciente</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo, techs }) => {
          return (
            <article className="portfolio__item">
              <div className="protfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <h5>{techs}</h5>
              <div className="portfolio__item-cta">
                {github ? (
                  <a
                    href={github}
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </a>
                ) : null}
                {demo ? (
                  <a
                    href={demo}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Demo
                  </a>
                ) : null}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
