import React from "react";
import "./header.css";
import CTA from "./CTA";
import image from "../../assets/me.png";
import { Hidden } from '@material-ui/core';
import { SocialIcons } from '../../content/SocialIcons';


const scroll__down = 'MÁS --->'

const Header = () => {
  return (
    <section id="cv">
    <header>
      <div className="container header__container">

        <h5>Hola Soy </h5>
        <h1>Brandon Fabila</h1>
        <h5>Programador Full Stack</h5>

        <CTA />
        <Hidden smDown  >
          <SocialIcons  />
        </Hidden>
        <div className="me">
          <img src={image} alt={image} />
        </div>

        <a href="#contact" className="scroll__down">
          {scroll__down}
        </a>

      </div>
    </header>
    </section>
  );
};

export default Header;
