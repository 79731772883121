import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import Portfolio from "../components/home/Home";
import { LogoLink } from "../components/logo/LogoLink";

import { Home } from "../pages/Home";
// import { Resume } from "../pages/Resume";
import { PageNotFound } from "../pages/PageNotFound";

// const Resume = lazy(() => import("../pages/Resume"));
// const PageNotFound = lazy(() => import("../pages/PageNotFound"));

export const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <LogoLink />
      <Router>
        <HelmetMeta />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/informacion" exact component={Portfolio} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};
