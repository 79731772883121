import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    svgHover: {
        fill: theme.palette.foreground.default,
        "&:hover": {
            fill: theme.palette.primary.main,
        },
        transition: "all 0.5s ease",
    },
}));

export const Logo = () => {
    const classes = useStyles();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            className={classes.svgHover}
            
            >
            <circle cx="250" cy="250" r="199.13" stroke="white" stroke-width="50" fill="none" />
            <circle cx="250" cy="250" r="224.13" stroke="black" stroke-width="50" fill="none" />
        </svg>
    );
};
