import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
// import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'

// const nodemailer = require("nodemailer");
// const transporter = nodemailer.createTransport({
//   host: "smtp.forwardemail.net",
//   port: 465,
//   secure: true,
//   auth: {
//     // TODO: replace `user` and `pass` values from <https://forwardemail.net>
//     user: 'bfabilarosas@gmail.com',
//     pass: 'REPLACE-WITH-YOUR-GENERATED-PASSWORD'
//   }
// });

const Contact = () => {
  const [formData, setFormData] = React.useState({ name: '', email: '', message: '' });

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Evita que el formulario se envíe automáticamente
    const { name, email, message } = formData;
    const subject = encodeURIComponent(`DeveloperCDMX: ${name}`);
    const body = encodeURIComponent(`${message}.
    Mi contacto alternativo: ${email}`);
    const mailtoUrl = `mailto:bfabilarosas@gmail.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoUrl;
  };

  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>bfabilarosas@gmail.com</h5>
            <a href="mailto:bfabilarosas@gmail.com" target="_blank" rel="noreferrer">Enviar mensaje</a>
          </article>
          {/* <article className='contact__option'>
            <RiMessengerLine  className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>facebook.com</h5>
            <a href="https://facebook.com" target="_blank" rel="noreferrer">Enviar mensaje</a>
          </article> */}
          <article className='contact__option'>
            <BsWhatsapp  className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <a href="https://api.whatsapp.com/send/?phone=5545016453" target="_blank" rel="noreferrer">Enviar Mensaje</a>
          </article>
        </div>
        {/* END OF CONTACT OPTION */}
        
        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Nombre completo"
            required
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <input
            type="text"
            name="email"
            placeholder="Contacto alternativo"
            required
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
          <textarea
            name="message"
            rows="7"
            placeholder={formData.name && !formData.message ? `Hola ${formData.name}! Me comunicare contigo lo más pronto posible, por favor inserta un mensaje indicando el motivo de contacto.` :"Mensaje"}
            required
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Enviar
          </button>
        </form>
      </div>
    </section>
  )
}

export default Contact